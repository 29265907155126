<template>
  <!-- 改了 -->
  <div class="main">
    <ul class="sidebar">
      <!-- @click="showKf = !showKf" -->
      <li class="line-li" @click="dialogVisible=true">
        <!-- <img src="../assets/sdImages/nav/Frame37slices/kefu1@2x.png" alt="" />
        <a class="phone" href="tel:4008066111">在线客服</a> -->
        <img src="../assets/sdImages/nav/yuyueyanshi 1@2x.png" alt="" />
        <a class="phone" href="tel:4008066111">预约演示</a>
      </li>
      <li class="line-li">
        <!-- <img src="../assets/image/ewm-ico.png" alt="" /> -->
        <img src="../assets/sdImages/nav/Frame37slices/weixin1@2x.png" alt="" />
        <div>微信咨询</div>
        <div class="ewm">
          <img src="@/assets/sdImages/code/gzh.png" alt="" />
          微信咨询专家
        </div>
      </li>
      <!-- @click="topage('/customizing')" -->
      <li class="dhli">
        <!-- <img src="../assets/image/bj-ico.png" alt="" /> -->
        <img src="../assets/sdImages/nav/Frame37slices/dianhua-21@2x.png" alt="" />
        <div>电话咨询</div>
        <div class="dhzx">
          <div class="left">
            <img src="../assets/sdImages/nav/Group1584@2x.png" alt="" />
          </div>
          <div class="right">
            <div class="text">咨询电话</div>
            <div class="text2">189 5511 0833</div>
          </div>
        </div>
      </li>
    </ul>
    <el-backtop :right="40" :bottom="40"></el-backtop>
    <kefu :showKf="showKf" class="kefuss" @closeKf="showKf = false"></kefu>
    <demonstrate
      :dialogVisible="dialogVisible"
      @closeDialog="closeDialog"
    ></demonstrate>
  </div>
</template>
<script>
import kefu from "./kefu.vue";
import demonstrate from "./demonstrate";
export default {
  components: {
    kefu,
    demonstrate
  },
  data() {
    return {
      showKf: false,
      dialogVisible:false
    }
  },
  methods: {
    closeDialog() {
      this.dialogVisible = false;
    },
    topage(e) {
      this.active = e;
      this.$router.replace({ path: e });
      const { aplus_queue } = window;
      aplus_queue.push({
        action: "aplus.sendPV",
        arguments: [
          {
            is_auto: false,
          },
          {
            param1: "111",
            param2: "222",
          },
        ],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.kefuss {
  width: 600px;
  height: 520px;
  position: fixed;
  right: 120px;
  bottom: 0;
  z-index: 999;
}

.sidebar {
  position: fixed;
  right: 20px;
  top: 40%;
  z-index: 999;
  background: linear-gradient(205deg, #3a89fe 0%, #27b1e5 100%);
  border-radius: 50px 50px 50px 50px;
  padding-top: 10px;
  padding-bottom: 10px;

  li {
    // min-width: 72px;
    // min-height: 72px;
    position: relative;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    // margin-bottom: 30px;
    // box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.4);
    &:hover {
      box-shadow: none;

      .ewm {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 12px;
        color: #a2a7ab;
        font-style: normal;
        text-transform: none;
      }
    }

    img {
      width: 30px;
      height: 30px;
      margin-bottom: 5px;
    }

    font-family: PingFang SC,
    PingFang SC;
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
    font-style: normal;
    text-transform: none;
    position: relative;

    .phone {
      cursor: pointer;
      color: #ffffff;
    }

    .ewm {
      position: absolute;
      right: calc(100% + 10px);
      top: 125%;
      transform: translateY(-50%);
      width: 178px;
      height: 178px;
      background: #ffffff;
      box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.1),
        0px 8px 10px 1px rgba(0, 0, 0, 0.06),
        0px 3px 14px 2px rgba(0, 0, 0, 0.05);
      border-radius: 12px 12px 12px 12px;
      display: none;
      box-sizing: border-box;

      &::after {
        content: "";
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-left-color: #ffffff;
        position: absolute;
        right: -11px;
        top: 20%;
        transform: translateY(-50%);
      }

      img {
        width: 132px;
        height: 131px;
      }
    }
  }

  .line-li::after {
    content: "";
    width: 80%;
    border: 1px solid #ffffff;
    position: absolute;
    left: 10px;
    top: 70px;
    z-index: 9999;
    opacity: 0.3;
  }

  li:hover::before {
    content: "";
    width: 56px;
    height: 55px;
    background: #f3fbff;
    border-radius: 6px 6px 6px 6px;
    opacity: 0.3;
    position: absolute;
  }

  .dhli {
    .dhzx {
      position: absolute;
      right: calc(100% + 10px);
      width: 181px;
      height: 52px;
      background: #ffffff;
      box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.1),
        0px 8px 10px 1px rgba(0, 0, 0, 0.06),
        0px 3px 14px 2px rgba(0, 0, 0, 0.05);
      border-radius: 12px 12px 12px 12px;
      border-radius: 8px 8px 8px 8px;
      display: none;
      box-sizing: border-box;

      &::after {
        content: "";
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-left-color: #ffffff;
        position: absolute;
        right: -11px;
        top: 45%;
      }
    }
  }

  .dhli:hover {
    .dhzx {
      display: flex;
      align-items: center;
      padding-left: 17px;

      .left {
        margin-right: 8px;
        display: flex;
        align-items: center;

        img {
          width: 29px;
          height: 29px;
        }
      }

      .right {
        .text {
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 12px;
          color: #020814;
          font-style: normal;
          text-transform: none;
        }

        .text2 {
          font-family: DIN Alternate, DIN Alternate;
          font-weight: bold;
          font-size: 14px;
          color: #3a89fe;
          font-style: normal;
          text-transform: none;
        }
      }
    }
  }
}
</style>
