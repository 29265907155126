import api from "@/http/api";
import http from "@/http/http";
export function scrollToLocation(toHeight) {
  const scrollToTop = document.documentElement.scrollTop   //滚动条距离顶部的距离
  $("html,body").animate({
    scrollTop: scrollToTop + toHeight
  }, 500);
}
// 官网菜单点击记录
export function staClickMenu(menuName) {
  http.get(api.saveClick, {
    menu: menuName,
  });
}