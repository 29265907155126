<template>
  <!-- 改了 -->
  <div :class="show ? 'h5' : 'wtop'" :style="style">
    <!-- <div style="color:#ff0000;background:#843900;height:32px;font-weight:bold;width:100%;position:fixed;top:0;line-height:32px;" v-show="!show">网站在升级中...</div> -->
    <div class="maintop">
      <!-- <div class="top_top">
         <div>北京善达信息有限公司欢迎您！</div>
         <div>联系客服：4008066111</div>
         </div> -->
      <div class="top_con">
        <div class="con_left">
          <!-- <img src="../assets/ximg/logob.png" alt="善达信息" title="善达信息" v-if="show" @click="topage('/')"/>
           <img src="../assets/ximg/logo.png" alt="善达信息" title="善达信息" v-else @click="topage('/')"/> -->
          <img src="../assets/sdImages/home/logo1.png(1)/logo1.png" alt="善达信息" title="善达信息" @click="topage('/')" />
          <!-- <img src="../assets/sdImages/home/logo2.png" alt="善达信息" title="善达信息" v-else @click="topage('/')"/> -->
          <!-- <div class="boxede">
            <div class="txtr">高新技术企业</div>
            <div class="txtr">专注软件开发</div>
           </div> -->
        </div>
        <div class="menu" v-show="show">
          <div class="phone">
            <!-- <a href="tel:189 5511 0833">189 5511 0833</a> -->
          </div>
          <div class="right_btn" @click="dialogVisible = false, clickMenu('免费试用')">免费试用</div>
          <img src="../assets/ximg/menu.png" alt="menu" @click="toMenu" />
        </div>
        <div class="con_right" v-show="!show">
          <div class="txtLine">
            <div class="btxt" :class="active == '/' ? 'active' : ''" @click="topage('/')">
              <a href="#" title="">首页</a>
            </div>
            <div class="btxt" :class="active == '/collection' ||
    active == '/chatAi' ||
    active == '/wky' ||
    active == '/shoppingMall' ||
    active == '/chainReform' ||
    active == '/computing' ||
    active == '/digital'
    ? 'active'
    : ''
    ">
              <a href="#" title="">产品与解决方案</a>
              <div class="up-down">
                <img src="../assets/sdImages/nav/Icon@2x(1).png" alt="" class="img11" />
                <img src="../assets/sdImages/nav/Icon@2x.png" alt="" class="img22" />
                <!-- <i class="el-icon-arrow-up img11"></i>
                <i class="el-icon-arrow-down img22"></i> -->
              </div>
              <div class="big-list">
                <div class="list fadeInDow">
                  <!-- <div class="item" @click="topage('/collection')">NFT数藏</div>
                <div class="item" @click="topage('/wky')">微客赚</div>
                <div class="item" @click="topage('/computing')">
                  边缘云计算平台
                </div>
                <div class="item" @click="topage('/digital')">数字钱包</div>
                <div class="item" @click="topage('/shoppingMall')">
                  电商系统
                </div>
                <div class="item" @click="topage('/chainReform')">企业链改</div>
                <div class="item" @click="topage('/chatAi')">星科AI</div>
                <div class="item" @click="noOpen('/save')">元宇宙</div> -->
                  <div class="list-left">
                    <div class="left-item" :class="{ active: current2 == 0 }" @click="current2 = 0">
                      <img src="../assets/sdImages/nav/slices/Group1620@2x.png" alt="" class="logoimg" />
                      <div class="text">产品中心</div>
                      <img src="../assets/sdImages/nav/Icondd.png" alt="" class="btnimg" v-if="current2 == 0" />
                      <img src="../assets/sdImages/nav/slices/Icon@2x(1).png" alt="" class="btnimg" v-else>
                    </div>
                    <div class="left-item" :class="{ active: current2 == 1 }" @click="current2 = 1">
                      <img src="../assets/sdImages/nav/slices/Group1621@2x.png" alt="" class="logoimg" />
                      <div class="text">解决方案</div>
                      <img src="../assets/sdImages/nav/Icondd.png" alt="" class="btnimg" v-if="current2 == 1" />
                      <img src="../assets/sdImages/nav/slices/Icon@2x(1).png" alt="" class="btnimg" v-else />
                    </div>
                  </div>
                  <div class="list-right">
                    <template v-if="current2 == 0">
                      <div class="right-item">
                        <div class="title">
                          <img src="../assets/sdImages/nav/Frame35slices/a-zhineng71@2x.png" alt="" />
                          <div>智能客服</div>
                        </div>
                        <div class="line"></div>
                        <div class="content" @click="clickMenu('智能质检', '/quality-check')">智能质检</div>
                        <div class="content" @click="clickMenu('智能外呼机器人', '/outbound')">智能外呼机器人</div>
                        <div class="content" @click="clickMenu('智能电话机器人', '/phone-robot')">智能电话机器人</div>
                        <div class="content" @click="clickMenu('坐席助手', '/seat')">坐席助手</div>
                        <div class="content" @click="clickMenu('文字客服机器人', '/text-robot')">文字客服机器人</div>
                      </div>
                      <div class="right-item">
                        <div class="title">
                          <img src="../assets/sdImages/nav/yuyinguanli1@2x.png" alt="" />
                          <div>语音智控</div>
                        </div>
                        <div class="line"></div>
                        <div class="content" @click="clickMenu('语音智控', '/ai-voice-ctrl')">语音智控</div>

                        <div class="title ts-title">
                          <img src="../assets/sdImages/nav/Frame35slices/wulianwang-21@2x.png" alt="" />
                          <div>物联网云平台</div>
                        </div>
                        <div class="line"></div>
                        <div class="content" @click="clickMenu('智能光伏物联网监测平台', '/case2')">智能光伏物联网监测平台</div>
                        <div class="content" @click="clickMenu('分布式视频监控平台', '/case1')">分布式视频监控平台</div>
                      </div>
                      <div class="right-item">
                        <div class="title">
                          <img src="../assets/sdImages/nav/Frame35slices/damoxing1@2x.png" alt="" />
                          <div>大模型</div>
                        </div>
                        <div class="line"></div>
                        <div class="content" @click="clickMenu('私有化大模型', '/gpt-private')">私有化大模型</div>
                        <div class="content" @click="clickMenu('大模型应用', '/gpt-app')">大模型应用</div>
                      </div>
                    </template>

                    <template v-if="current2 == 1">
                      <div class="right-item ts-item">
                        <div class="right-group">
                          <div class="title">
                            <img src="../assets/sdImages/nav/slices/zhihuijinrong-011@2x.png" alt="" />
                            <div>智慧金融</div>
                          </div>
                          <div class="content" @click="clickMenu('金融行业智能客服解决方案', '/icss-sfi')">金融行业智能客服解决方案</div>
                        </div>
                        <div class="right-group">
                          <div class="title">
                            <img src="../assets/sdImages/nav/slices/lvyou1@2x.png" alt="" />
                            <div>文化旅游</div>
                          </div>
                          <div class="content" @click="clickMenu('智慧文旅解决方案', '/culture-tourism')">智慧文旅解决方案</div>
                        </div>
                        <div class="right-group">
                          <div class="title">
                            <img src="../assets/sdImages/nav/slices/yuanqufuwu1@2x.png" alt="" />
                            <div>工业与园区</div>
                          </div>
                          <div class="content" @click="clickMenu('智慧园区解决方案', '/community')">智慧园区解决方案</div>
                        </div>
                      </div>
                      <div class="right-item ts-item2">
                        <div class="right-group right-group-ts">
                          <div class="title">
                            <img src="../assets/sdImages/nav/slices/zhengwu-gongkaizhinan1@2x.png" alt="" />
                            <div>政务及公共服务</div>
                          </div>
                          <div class="content" @click="clickMenu('政务大数据解决方案', '/gov-data')">政务大数据解决方案</div>
                          <div class="content" @click="clickMenu('公共服务行业智能客服解决方案','/case3')">
                            公共服务行业智能客服解决方案
                          </div>
                          <div class="content" @click="clickMenu('政务大模型智能客服接解决方案', '/gov-model')">
                            政务大模型智能客服接解决方案
                          </div>
                        </div>
                        <div class="right-group">
                          <div class="title">
                            <img src="../assets/sdImages/nav/slices/xinnengyuanqiche 1@2x.png" alt="" />
                            <div>能源与汽车</div>
                          </div>
                          <div class="content" @click="clickMenu('智能客服营销一体化解决方案', '/icsm-is')">智能客服营销一体化解决方案</div>
                        </div>
                      </div>
                      <div class="right-item ts-item3">
                        <div class="right-group right-group-ts2">
                          <div class="title">
                            <img src="../assets/sdImages/nav/slices/nongye1@2x.png" alt="" />
                            <div>智慧农业</div>
                          </div>
                          <div class="content" @click="clickMenu('智慧农业平台解决方案', '/agr-platform')">智慧农业平台解决方案</div>
                          <div class="content" @click="clickMenu('农产品溯源解决方案', '/agr-prod')">农产品溯源解决方案</div>
                        </div>
<!--                        <div class="right-group">
                          <div class="title">
                            <img src="../assets/sdImages/nav/slices/lingshou21@2x.png" alt="" />
                            <div>连锁零售</div>
                          </div>
                          <div class="content" @click="clickMenu('全渠道联络中心解决方案')">全渠道联络中心解决方案</div>
                        </div>-->
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div
              class="btxt"
              :class="active == '/save' ? 'active' : ''
              "
              @click="topage('/save')"
            >
              <a href="#" title="">定制开发</a>
            </div>
            <div
              class="btxt"
              :class="
                active == '/gallery' || active == '/gallerypc' ? 'active' : ''
              "
              @click="topage('/gallery')"
            >
              <a href="#" title="">案例展示</a>
            </div> -->
            <div class="btxt" :class="active == '/newsCenter' || active == '/teamdetal'
    ? 'active'
    : ''
    " @click="topage('/newsCenter', 0)">
              <a href="#" title="">新闻中心</a>
              <!-- <div class="up-down">
                <img src="../assets/sdImages/nav/Icon@2x(1).png" alt="" class="img11" />
                <img src="../assets/sdImages/nav/Icon@2x.png" alt="" class="img22" />
              </div> -->
              <!-- <div class="big-list">
                <div class="list fadeInDow list-ts">
                  <div class="right_item" @click="topage('/newsCenter', 0)">
                    <div class="item_con">
                      <div class="con1">公司新闻</div>
                      <div class="con2">公司大事、乐事、开心事分享集中地！</div>
                    </div>
                    <div class="item_img">
                      <img src="../assets/sdImages/nav/Frame36slices/Group1601@2x.png" alt="" />
                    </div>
                  </div>
                  <div class="right_item" @click="topage('/newsCenter', 1)">
                    <div class="item_con">
                      <div class="con1">行业动态</div>
                      <div class="con2">行业走向时刻关注！</div>
                    </div>
                    <div class="item_img">
                      <img src="../assets/sdImages/nav/Frame36slices/Group1615@2x.png" alt="" />
                    </div>
                  </div>
                  <div class="right_item" @click="topage('/newsCenter', 2)">
                    <div class="item_con">
                      <div class="con1">技术学堂</div>
                      <div class="con2">技术时刻学习，紧跟行业更新步伐！</div>
                    </div>
                    <div class="item_img">
                      <img src="../assets/sdImages/nav/Frame36slices/Group1616@2x.png" alt="" />
                    </div>
                  </div>
                </div>
              </div> -->
            </div>

            <div class="btxt" :class="active == '/about' ||
    active == '/recruiting' ||
    active == '/recuiDetail'
    ? 'active'
    : ''
    ">
              <a href="#" title="">关于我们</a>
              <div class="up-down">
                <img src="../assets/sdImages/nav/Icon@2x(1).png" alt="" class="img11" />
                <img src="../assets/sdImages/nav/Icon@2x.png" alt="" class="img22" />
              </div>
              <div class="big-list big-list-ts">
                <div class="list fadeInDow list-ts">
                  <div class="right_item right_item-ts1" @click="topage('/about', 0)">
                    <div class="item_con">
                      <div class="con1">公司介绍</div>
                      <div class="con2">专注互联网信息化服务</div>
                    </div>
                  </div>
                  <div class="right_item right_item-ts2" @click="topage('/about', 1)">
                    <div class="item_con">
                      <div class="con1">发展历程</div>
                      <div class="con2">蒸蒸日上，节节高升，末来可期！</div>
                    </div>
                  </div>
                  <div class="right_item right_item-ts3" @click="topage('/about', 2)">
                    <div class="item_con">
                      <div class="con1">荣誉资质</div>
                      <div class="con2">高企认证、CMM13认证、人工智能企业</div>
                    </div>
                  </div>
                  <div class="right_item right_item-ts4" @click="topage('/about', 3)">
                    <div class="item_con">
                      <div class="con1">地理位置</div>
                      <div class="con2">智能软件科技园内</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="btxt" :class="active == '/setParty' ? 'active' : ''" >
              <a href="#" title="">党群建设</a>
              <div class="up-down">
                <img src="../assets/sdImages/nav/Icon@2x(1).png" alt="" class="img11" />
                <img src="../assets/sdImages/nav/Icon@2x.png" alt="" class="img22" />
              </div>
              <div class="dq-list fadeInDow">
                  <div @click="topage('/setParty')">简介</div>
                  <div @click="topage('/setPartyDetail','0')">品牌特色</div>
                  <div @click="topage('/setParty','2')">党群动态</div>
              </div>
            </div>
          </div>
          <div class="phone"></div>
          <!-- topage('/customizing')  -->
          <div class="right_btn" :class="active == '/customizing' ? 'actbtn' : ''"
            @click="dialogVisible = true, clickMenu('免费试用')">
            免费试用
          </div>
        </div>
      </div>
    </div>
    <!-- 手机端点开右上菜单图片显示 -->
    <div>
      <van-overlay :show="mshow" @click="mshow = false">
        <el-collapse-transition>
          <div class="txtLine" v-show="mshow" @click.stop>
            <div class="btxt" :class="active == '/' ? 'active' : ''" @click="topage('/')">
              首页
            </div>

            <div class="menu">
              <div class="laber" @click="smshow = !smshow">
                产品与解决方案
                <i :class="smshow ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
              </div>
              <div class="mneut fadeInDow" v-show="smshow">
                <div class="btxt" @click="clickMenu('智能质检')">
                  智能质检
                </div>
                <div class="btxt" @click="clickMenu('智能外呼机器人')">
                  智能外呼机器人
                </div>
                <div class="btxt" @click="clickMenu('智能电话机器人')">
                  智能电话机器人
                </div>
                <div class="btxt" @click="clickMenu('坐席助手', '/seat')">
                  坐席助手
                </div>
                <div class="btxt" @click="clickMenu('文字客服机器人')">
                  文字客服机器人
                </div>
                <div class="btxt" @click="clickMenu('语音智控')">
                  语音智控
                </div>
                <div class="btxt" :class="active == '/case2' ? 'active' : ''" @click="clickMenu('智能光伏物联网监测平台', '/case2')">
                  智能光伏物联网监测平台
                </div>
                <div class="btxt" :class="active == '/case1' ? 'active' : ''" @click="clickMenu('分布式视频监控平台', '/case1')">
                  分布式视频监控平台
                </div>
                <div class="btxt" @click="clickMenu('私有化大模型')">
                  私有化大模型
                </div>
                <div class="btxt" @click="clickMenu('大模型应用')">
                  大模型应用
                </div>
                <div class="btxt" @click="clickMenu('金融行业智能客服解决方案')">
                  金融行业智能客服解决方案
                </div>
                <div class="btxt" @click="clickMenu('智慧文旅解决方案')">
                  智慧文旅解决方案
                </div>
                <div class="btxt" @click="clickMenu('智慧园区解决方案')">
                  智慧园区解决方案
                </div>
                <div class="btxt" @click="clickMenu('政府大数据解决方案')">
                  政府大数据解决方案
                </div>
                <div class="btxt" :class="active == '/case3' ? 'active' : ''" @click="clickMenu('公共服务行业智能客服解决方案','/case3')">
                  公共服务行业智能客服解决方案
                </div>
                <div class="btxt" @click="clickMenu('政务大模型智能客服接解决方案')">
                  政务大模型智能客服接解决方案
                </div>
                <div class="btxt" @click="clickMenu('智能客服营销一体化解决方案')">
                  智能客服营销一体化解决方案
                </div>
                <div class="btxt" @click="clickMenu('智慧农业平台解决方案')">
                  智慧农业平台解决方案
                </div>
                <div class="btxt" @click="clickMenu('农产品溯源解决方案')">
                  农产品溯源解决方案
                </div>
<!--                <div class="btxt" @click="clickMenu('全渠道联络中心解决方案')">
                  全渠道联络中心解决方案
                </div>-->
              </div>
            </div>
            <div class="btxt" :class="active == '/newsCenter' ? 'active' : ''" @click="topage('/newsCenter', 0)">
              新闻中心
            </div>
            <div class="btxt" :class="active == '/about' ? 'active' : ''" @click="topage('/about', 0)">
              关于我们
            </div>
            <div class="btxt" :class="active == '/setParty' ? 'active' : ''" @click="topage('/setParty')">
              党群建设
            </div>
          </div>
        </el-collapse-transition>
      </van-overlay>
    </div>
    <demonstrate :dialogVisible="dialogVisible" @closeDialog="closeDialog"></demonstrate>
  </div>
</template>

<script>
import demonstrate from "./demonstrate.vue";
import { staClickMenu } from '../utils/index';
export default {
  data() {
    return {
      show: false, //判断是否是手机
      mshow: false,
      active: "/index",
      actives: "index",
      style: {
        backgroundColor: "",
      },
      color: false,
      smshow: true,
      current2: 0,
      dialogVisible: false
    };
  },
  components: {
    demonstrate
  },
  created() {
    this.active = this.$route.path;
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }
  },
  watch: {
    "$store.getters.phone": function (val) {
      this.show = val;
    },
    deep: true,
    immediate: true,
  },
  methods: {
    // 统计菜单点击
    clickMenu(menuName, url) {
      if (url) {
        this.$router.replace({
          path: url,
        });
      } else {
        if (menuName != '免费试用') {
          this.$toast("敬请期待");
        }
      }
      staClickMenu(menuName)
    },
    closeDialog() {
      this.dialogVisible = false
    },
    gotoSomePage() {
      this.$toast("敬请期待");
    },
    _isMobile() {
      //检测是否为手机
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    toMenu() {
      this.mshow = !this.mshow;
    },
    noOpen() {
      this.$toast("敬请期待");
    },
    handleScroll() {
      //滚动头部背景变色
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // console.log('scrollTop---->',scrollTop)
      // let offsetTop = document.querySelector(".header").offsetTop;
      //设置背景颜色的透明读
      if (scrollTop) {
        // this.style.backgroundColor = `rgba(61, 61, 61,${
        //   scrollTop / (scrollTop + 80)
        // })`;
        // this.color = true;
        this.style.backgroundColor = "#ffffff";
      } else if (scrollTop == 0) {
        // this.style.backgroundColor = `rgba(61, 61, 61,${scrollTop / (scrollTop + 80)
        //   })`;
        // this.color = false;
      }
      console.log('scrollTop', scrollTop)
    },
    to(url) {
      location.href = url;
    },

    topage(e, val) {
      this.active = e;
      // if(val||val==0){
      //   this.$router.replace({ path: `${e}?current=${val}` });
      // }else {
      //   this.$router.replace({ path: `${e}` });
      // }

      this.$router.replace({
        path: e,
        query: {
          current: val,
        },
      });
      const { aplus_queue } = window;
      aplus_queue.push({
        action: "aplus.sendPV",
        arguments: [
          {
            is_auto: false,
          },
          {
            param1: "111",
            param2: "222",
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$t-mf: "Arial";

.wtop {
  z-index: 9999;
  width: 100%;
  position: fixed;
  width: 100%;
  z-index: 999;
  height: 64px;
  max-width: 100%;
  min-width: 1200px;
  margin: 0 auto;
  background: #ffffff;

  // position: relative;
  .maintop {
    max-width: 1200px;
    min-width: 750px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    .top_top {
      width: 100%;
      margin: 0 auto;
      // margin-top: 15px;
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      // font-family: Microsoft YaHei;
      font-weight: 400;
      color: #dedede;
      margin-top: 32px;
    }

    .top_con {
      // max-width:1200px;
      min-width: 750px;
      width: 100%;
      margin: 0 auto;
      // margin-top: 10px;
      display: flex;
      // justify-content: space-between;

      .con_left {
        display: flex;
        align-items: center;

        img {
          width: 120px;
          height: auto;
        }

        .boxede {
          margin: 0 15px;

          .txtr {
            white-space: nowrap;
            color: #000000;
            font-size: 10px;
          }
        }
      }

      .menu {
        display: flex;
      }

      .con_right {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .txtLine {
          display: flex;
          color: #fff;
          align-items: center;

          .btxt {
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #2B2C2F;
            font-style: normal;
            text-transform: none;
            // color: #eee;
            text-align: center;
            min-width: 105px;
            // height: 45px;
            line-height: 65px;
            margin: 0 10px;
            cursor: default;
            display: flex;
            align-items: center;
            justify-content: center;

            .up-down {
              width: 16px;
              height: 16px;
              margin-left: 6px;

              img {
                width: 100%;
                height: 100%;
              }

              .img11 {
                display: none;
              }

              .img22 {
                display: block;
              }
            }

            a {
              color: #000000;
            }
            .dq-list {
              width: 100px;
              padding-top: 4px;
              position: absolute;
              left: 220px;
              top: 65px;
              right: 0;
              margin: auto;
              box-sizing: border-box;
              display: none;
              background: #ffffff;
              border-radius: 24px 24px 24px 24px;
              div{
                line-height: 50px;
              }
              div:hover{
                color: #3a89fe;
              }
            }

            .big-list {
              width: 1056px;
              // height: 340px;
              padding-top: 4px;
              position: absolute;
              // left: -196%;
              top: 65px;
              left: 0;
              right: 0;
              margin: auto;
              box-sizing: border-box;
              display: none;
            }

            .big-list-ts {

              .con1,
              .con2 {
                color: #ffffff !important;
              }
            }

            .list-ts {
              height: 185px !important;
              box-sizing: border-box;
              display: flex;
              justify-content: space-between;
              align-items: center;

              .right_item-ts1 {
                width: 239px !important;
                padding-right: 0 !important;
                background: url(~@/assets/sdImages/nav/Group1617.png) no-repeat !important;
                background-size: cover !important;
                background-position: center center !important;
                border-radius: 5px;
                transition: 0.35s;
                overflow: hidden;
              }

              .right_item-ts1:hover {
                // background-size: 200% 200% !important;
                background: linear-gradient(180deg, #3B8AFF 0%, #2FA1F0 100%) !important;
              }

              .right_item-ts2 {
                width: 239px !important;
                padding-right: 0 !important;
                background: url(~@/assets/sdImages/nav/Group1618.png) no-repeat !important;
                background-size: cover !important;
                background-position: center center !important;
                border-radius: 5px;
                transition: 0.35s;
                overflow: hidden;
              }

              .right_item-ts2:hover {
                // background-size: 200% 200% !important;
                background: linear-gradient(180deg, #3B8AFF 0%, #2FA1F0 100%) !important;
              }

              .right_item-ts3 {
                width: 239px !important;
                padding-right: 0 !important;
                background: url(~@/assets/sdImages/nav/Group1619.png) no-repeat !important;
                background-size: cover !important;
                background-position: center center !important;
                border-radius: 5px;
                transition: 0.35s;
                overflow: hidden;
              }

              .right_item-ts3:hover {
                // background-size: 200% 200% !important;
                background: linear-gradient(180deg, #3B8AFF 0%, #2FA1F0 100%) !important;
              }

              .right_item-ts4 {
                width: 239px !important;
                padding-right: 0 !important;
                background: url(~@/assets/sdImages/nav/Group1622.png) no-repeat !important;
                background-size: cover !important;
                background-position: center center !important;
                border-radius: 5px;
                transition: 0.35s;
                overflow: hidden;
              }

              .right_item-ts4:hover {
                // background-size: 200% 200% !important;
                background: linear-gradient(180deg, #3B8AFF 0%, #2FA1F0 100%) !important;
              }

              .right_item {
                display: flex;
                justify-content: space-between;
                // border-bottom: 1px solid #eee;
                width: 322px;
                height: 101px;
                background: url(~@/assets/sdImages/nav/Maskgroup.png) no-repeat;
                background-size: cover;
                background-position: center center;
                align-items: center;
                padding-right: 10px;

                .item_img {
                  width: 72px;
                  height: 72px;
                  border-radius: 8px;
                  flex-shrink: 0;
                  overflow: hidden;

                  img {
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    transition: 0.35s;
                  }
                }

                .item_img img:hover {
                  transform: scale(1.1);
                }

                .item_con {
                  width: 100%;
                  // height: 180px;
                  display: flex;
                  flex-direction: column;
                  text-align: left;
                  margin-left: 17px;
                  justify-content: center;
                  // justify-content: space-between;
                  cursor: default;
                  position: relative;

                  .con1 {
                    // font-family: PingFang SC, PingFang SC;
                    font-weight: 600;
                    font-size: 18px;
                    color: #000000;
                    line-height: 30px;
                    text-align: left;
                    font-style: normal;
                    text-transform: none;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                  }

                  .con1:hover {
                    color: #3a89fe;
                  }

                  .con2 {
                    // font-family: PingFang SC, PingFang SC;
                    // width: 509px;
                    font-weight: 500;
                    font-size: 12px;
                    color: #6d727a;
                    line-height: 30px;
                    text-align: left;
                    font-style: normal;
                    text-transform: none;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                  }

                  .con3 {
                    display: flex;
                    margin-top: 30px;

                    .con3_tab {
                      width: 56px;
                      height: 22px;
                      border: 1px solid #dadada;
                      border-radius: 2px;
                      text-align: center;
                      margin-right: 5px;
                      line-height: 22px;
                      font-size: 10px;
                      // font-family: Microsoft YaHei;
                      font-weight: 400;
                      color: #666666;
                      cursor: default;
                    }
                  }

                  .con4 {
                    cursor: default;
                    // font-family: PingFang SC, PingFang SC;
                    font-weight: 500;
                    font-size: 14px;
                    color: #8d8f94;
                    line-height: 21px;
                    text-align: left;
                    font-style: normal;
                    text-transform: none;
                    display: flex;
                    align-items: center;

                    img {
                      margin-right: 5px;
                    }
                  }

                  .btn {
                    width: 112px;
                    height: 40px;
                    border-radius: 2px;
                    border: 1px solid #2b6bff;
                    font-size: 16px;
                    // font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #2b6bff;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    right: 30px;
                    bottom: 0px;
                    cursor: pointer;
                  }
                }
              }
            }

            .list {
              display: none;
              // box-shadow: 0.13333rem 0.06667rem 0.13333rem #000000;
              // padding: 10px;
              // background: black;
              width: 1056px;
              height: 340px;
              background: #ffffff;
              border-radius: 24px 24px 24px 24px;
              // transition: all 0.25s ease 0s;
              padding: 18px 19px;
              box-sizing: border-box;

              .list-left {
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                font-size: 16px;
                color: #000000;
                line-height: 60px;
                text-align: left;
                font-style: normal;
                text-transform: none;
                margin-right: 43px;

                .left-item {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  height: 60px;
                  padding: 0 14px;
                  box-sizing: border-box;
                  cursor: pointer;

                  .logoimg {
                    width: 30px;
                    height: 30px;
                    margin-right: 18px;
                  }

                  .btnimg {
                    width: 14px;
                    height: 14px;
                    margin-left: 92px;
                  }
                }

                .active {
                  width: 256px;
                  height: 60px;
                  background: #f2f7ff;
                  border-radius: 6px 6px 6px 6px;
                }
              }

              .list-right {
                display: flex;
                margin-top: 15px;
                .ts-item {
                  margin-right: 68px !important;
                }
                .ts-item2 {
                  margin-right: 92px !important;
                }
                .ts-item3 {
                }

                .right-item {
                  margin-right: 38px;

                  .right-group {
                    margin-bottom: 42px;

                    .title {
                      line-height: 23px;
                    }

                    .content {
                      font-weight: 500;
                      font-size: 14px;
                      color: #262626;
                      line-height: 34px;
                      font-style: normal;
                      text-transform: none;
                    }
                  }

                  .right-group-ts {
                    margin-bottom: 60px;
                    .title {
                      margin-bottom: 13px;
                    }
                  }

                  .right-group-ts2 {
                    margin-bottom: 92px;
                    .title {
                      margin-bottom: 13px;
                    }
                  }
                  .ts-title {
                    margin-top: 24px;
                  }

                  .title {
                    font-weight: 600;
                    font-size: 16px;
                    color: #262626;
                    text-align: left;
                    font-style: normal;
                    text-transform: none;
                    display: flex;
                    align-items: center;
                    line-height: 0;

                    img {
                      width: 26px;
                      height: 26px;
                      margin-right: 12px;
                    }
                  }

                  .line {
                    width: 210px;
                    border: 1px solid #dfdfdf;
                    margin: 18px auto 12px auto;
                  }

                  .content {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 42px;
                    color: #262626;
                    text-align: left;
                    font-style: normal;
                    text-transform: none;
                    cursor: pointer;
                  }

                  .content:hover {
                    color: #3a89fe;
                  }
                }
              }

              .item:hover {
                font-weight: bold;
                white-space: nowrap;
              }
            }
          }

          .btxt:hover {
            opacity: 1;

            // font-size: 18px;
            // color: #fff;
            // border-bottom: 3px solid #2b6bff;
            a {
              color: #3a89fe !important;
            }

            .img11 {
              display: block;
            }

            .img22 {
              display: none;
            }

            .list {
              display: flex;

              // visibility: inherit;
              .item {
                cursor: pointer;
                white-space: nowrap;
              }
            }

            .big-list {
              display: block;
            }
            .dq-list{
              display: block;
            }
          }
        }

        .active {

          // border-bottom: 2px solid #2b6bff;
          a {
            color: #3a89fe !important;
            font-weight: 500;
          }

          .img11 {
            display: block !important;
          }

          .img22 {
            display: none !important;
          }
        }

        .phone {
          padding: 0 30px 0 30px;
          height: 48px;
          // background: url("../assets/sdImages/nav/Group1582@2x.png") left center no-repeat;
          // background-size: 22px 22px;
          line-height: 48px;
          margin-left: 100px;
          white-space: nowrap;

          font-weight: bold;
          font-size: 16px;
          color: #2b2c2f;
          font-style: normal;
          text-transform: none;
        }

        .right_btn {
          width: 94px;
          height: 37px;
          background: #3a89fe;
          border-radius: 4px 4px 4px 4px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 37px;
          text-align: center;
          font-style: normal;
          text-transform: none;
          // margin-left: 300px;
          cursor: pointer;
        }

        .actbtn,
        .right_btn:hover {
          cursor: pointer;
          border: 1px solid #2b6bff;
          background: #2b6bff;
          color: #ffffff;
        }
      }
    }
  }
}

.h5 {
  display: flex;
  justify-content: center;
  opacity: 0.85;
  background: rgba(61, 61, 61, 0.7);
  //  box-shadow: 0px 0px 48px 0px rgba(48, 81, 232, 0.2);
  z-index: 9999;
  width: 100%;
  position: fixed;

  .maintop {
    min-width: 100%;
    max-width: 750px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    .top_top {
      width: calc(100% - 64px);
      margin: 15px auto;
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      // font-family: Microsoft YaHei;
      font-weight: 400;
      color: #dedede;
    }

    .top_con {
      max-width: 750px;
      width: 100%;
      padding: 22px;
      box-sizing: border-box;
      // margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .con_left {
        display: flex;
        align-items: center;

        img {
          width: 200px;
          height: auto;
        }

        .boxede {
          margin: 0 15px;

          .txtr {
            white-space: nowrap;
            color: #ffffff;
            font-size: 20px;
          }
        }
      }

      .menu {
        display: flex;
        align-items: center;

        .phone {
          // padding: 0 40px 0 50px;
          // height: 48px;
          // background: url("../assets/image/phone.png") left center
          // 	no-repeat;
          // background-size: 48px 48px;
          // a {
          // 	font-size: 18px;
          // 	font-weight: 400;
          // 	color: #ffffff;
          // 	line-height: 48px;
          // }
          display: none;
        }

        .right_btn {
          display: none;
        }

        img {
          height: 48px;
        }
      }

      .con_right {
        display: flex;
        justify-content: space-between;

        .txtLine {
          display: flex;
          color: #fff;

          .btxt {
            font-size: 16px;
            // font-family: Microsoft YaHei;
            font-weight: 400;
            color: #eee;
            text-align: center;
            min-width: 74px;
            height: 45px;
            line-height: 45px;
            margin: 0 20px;
            cursor: default;
          }

          .btxt:hover {
            opacity: 1;
            // font-size: 18px;
            color: #fff;
            border-bottom: 3px solid #abb3c1;
          }
        }

        .active {
          border-bottom: 3px solid #2b6bff;
        }

        .phone {
          padding: 0 40px 0 50px;
          height: 48px;
          //background: url("../assets/image/phone.png") left center no-repeat;
          background-size: 48px 48px;
          font-size: 18px;
          font-weight: 400;
          color: #ffffff;
          line-height: 48px;
          white-space: nowrap;
        }

        .right_btn {
          width: 106px;
          height: 37px;
          border: 1px solid #ffffff;
          border-radius: 18px;
          text-align: center;
          line-height: 37px;
          font-size: 14px;
          // font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          // margin-left: 300px;
          cursor: pointer;
        }

        .right_btn:hover {
          cursor: pointer;
          border: 1px solid #2b6bff;
          background: #2b6bff;
        }
      }
    }
  }

  .txtLine {
    // display: flex;
    flex: 1;
    width: 85%;
    flex-direction: column;
    background: #ffffff;
    height: 100%;
    position: fixed;
    right: 0px;
    z-index: 9999;
    overflow: auto;
    box-sizing: border-box;
    padding-top: 100px;

    .menu {
      display: flex;
      flex-direction: column;

      .laber {
        font-size: 32px;
        // font-family: PingFang SC;
        font-weight: 500;
        color: #2a2d32;
        margin: 0 38px;
        flex: 1;
        text-align: left;
        min-height: 100px;
        line-height: 100px;
        border-bottom: 1px solid #eee;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .mneut {
      // width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background: #fffcfc;
      padding: 0 33px 0 80px;
    }

    .btxt {
      cursor: pointer;
      font-size: 32px;
      // font-family: PingFang SC;
      font-weight: 500;
      color: #2a2d32;
      margin: 0 38px;
      flex: 1;
      text-align: left;
      min-height: 100px;
      line-height: 100px;
      border-bottom: 1px solid #eee;
    }

    .active {
      opacity: 1;
      font-weight: bold;
      color: #3051e8;
    }
  }
}
</style>
