<template>
  <!-- 改了 -->
  <div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="1035px"
      append-to-body
      :before-close="cancelDialog"
    >
      <div class="dialog-box">
        <div class="left">
          <div class="title">即刻联系</div>
          <div class="con1">精准理解您的需求</div>
          <div class="con2">预约演示，您将免费获得：</div>
          <div class="con3">
            <img
              src="../assets/sdImages/nav/duihao-25@2x.png"
              alt=""
            />POC演示环境免费提供
          </div>
          <div class="con3">
            <img
              src="../assets/sdImages/nav/duihao-25@2x.png"
              alt=""
            />产品服务试用与指导
          </div>
          <div class="con3">
            <img
              src="../assets/sdImages/nav/duihao-25@2x.png"
              alt=""
            />业务发展成功案例分享
          </div>
          <div class="con3">
            <img
              src="../assets/sdImages/nav/duihao-25@2x.png"
              alt=""
            />1v1行业专家方案定制服务
          </div>
        </div>
        <div class="right">
          <div class="form-box">
            <div class="title">{{resultFlag?'预约成功':'预约演示'}}</div>
            <el-form
              label-position="top"
              label-width="80px"
              :model="formLabelAlign"
              :rules="rules"
              ref="ruleForm"
            >
              <template v-if="!resultFlag">
                <el-form-item label="联系人" prop="contact">
                <el-input
                  v-model="formLabelAlign.contact"
                  placeholder="请输入联系人姓名"
                  maxlength="30"
                ></el-input>
              </el-form-item>
              <el-form-item label="联系方式" prop="phone">
                <el-input
                  v-model="formLabelAlign.phone"
                  placeholder="请输入联系方式"
                  maxlength="11"
                ></el-input>
              </el-form-item>
              <el-form-item label="企业名称" prop="name">
                <el-input
                  v-model="formLabelAlign.name"
                  placeholder="请输入企业名称"
                  maxlength="30"
                ></el-input>
              </el-form-item>
              <el-form-item label="需求描述" prop="description">
                <el-input
                  type="textarea"
                  v-model="formLabelAlign.description"
                  :rows="4"
                  show-word-limit
                  placeholder="请输入你的需求,让我们更好的为您服务"
                  maxlength="100"
                ></el-input>
              </el-form-item>
              </template>
              <template v-if="resultFlag">
                <div style="height: 465px;">
                  <el-result icon="success" title="预约成功" subTitle="已成功帮您预约，我们的技术专家会在24小时内跟您联系"></el-result>
                </div>
              </template>
              <el-form-item>
                <div class="btn" @click="submitDialog" v-if="!resultFlag">立即预约</div>
                <div class="btn" @click="cancelDialog" v-if="resultFlag">关闭</div>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDialog">取 消</el-button>
        <el-button type="primary" @click="submitDialog">确 定</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formLabelAlign: {
        contact: "",
        phone: "",
        name: "",
        description: "",
      },
      rules: {
        contact: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        phone: [{ required: true, message: "请输入联系方式", trigger: "blur" }],
        name: [{ required: true, message: "请输入企业名称", trigger: "blur" }],
        description: [
          { required: true, message: "请输入需求描述", trigger: "blur" },
        ],
      },
      resultFlag:false
    };
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    cancelDialog() {
      this.resultFlag = false
      this.$emit("closeDialog");
    },
    submitDialog() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          // this.$axios({
          //   method: "post",
          //   url: "/website/websiteExternal/saveClient",
          //   params: this.formLabelAlign,
          // })
          //   .then((res) => {
          //     console.log('res----->',res)
          //   })
          //   .catch(() => { });

          let res = await this.$http.post(
            this.$api.saveClient,
            this.formLabelAlign
          );
          console.log("res----->", res);
          // this.$message({
          //   message: res.data.msg,
          //   type: 'success'
          // });

          // this.$notify({
          //   message: "已成功帮您预约，我们的技术专家会在24小时内跟您联系",
          //   type: 'success'
          // });
          // this.cancelDialog();
          this.$refs.ruleForm.resetFields();
          this.resultFlag = true
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.el-dialog) {
  margin-top:5vh !important;
}
:deep(.el-dialog__body) {
  padding: 0;
}

:deep(.el-form-item__label) {
  font-weight: 600;
  font-size: 18px;
  color: #2b2c2f;
  line-height: 30px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.dialog-box {
  display: flex;

  .left {
    width: 52%;
    height: 682px;
    // background: linear-gradient(180deg, #3A89FE 0%, #27B1E5 100%);
    background: url(~@/assets/sdImages/nav/Group12369.png) no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    margin-top: -30px;
    padding-top: 59px;
    padding-left: 66px;
    box-sizing: border-box;

    .title {
      font-weight: 600;
      font-size: 36px;
      color: #ffffff;
      font-style: normal;
      text-transform: none;
      margin-bottom: 15px;
    }

    .con1 {
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
      font-style: normal;
      text-transform: none;
      margin-bottom: 40px;
    }

    .con2 {
      font-weight: 400;
      font-size: 20px;
      color: #ffffff;
      font-style: normal;
      text-transform: none;
      margin-bottom: 20px;
    }

    .con3 {
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
      line-height: 36px;
      font-style: normal;
      text-transform: none;
      display: flex;
      align-items: center;
      img {
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }
    }
  }

  .right {
    width: 48%;

    .form-box {
      width: 75%;
      margin: 10px auto 0 auto;

      .title {
        font-weight: bold;
        font-size: 24px;
        color: #2b2c2f;
        line-height: 30px;
        font-style: normal;
        text-transform: none;
        margin-bottom: 40px;
      }

      .btn {
        width: 100%;
        height: 50px;
        background: #3a89fe;
        border-radius: 2px 2px 2px 2px;
        font-weight: 600;
        font-size: 18px;
        color: #ffffff;
        line-height: 50px;
        text-align: center;
        font-style: normal;
        text-transform: none;
        margin-top: 15px;
        cursor: pointer;
      }

      .btn:hover {
        opacity: 0.9;
      }
    }
  }
}
</style>
