<template>
  <!-- 改了 -->
  <footer>
    <!-- <ul class="footer-top Gwidth" v-if="active=='/'">
			<li>10年技术沉淀</li>
			<li>50+技术团队</li>
			<li>1000+客户案例</li>
			<li>7*24小时售后</li>
		</ul> -->
    <div class="footer-center Gwidth">
      <div class="footer-center-left">
        <div class="footer-center-left-item">
          <h5>关于我们</h5>
          <ul>
            <li @click="topage('/about', 0)">公司介绍</li>
            <li @click="topage('/about', 1)">发展历程</li>
            <li @click="topage('/about', 2)">荣誉资质</li>
            <li @click="topage('/about', 3)">地理位置</li>
          </ul>
        </div>
        <div class="footer-center-left-item">
          <h5>行业应用</h5>
          <ul>
            <li @click="clickMenu('金融行业智能客服解决方案', '/icss-sfi')">金融行业智能客服解决方案</li>
            <li @click="clickMenu('智慧文旅解决方案', '/culture-tourism')">智慧文旅解决方案</li>
            <li @click="clickMenu('智慧园区解决方案', '/community')">智慧园区解决方案</li>
            <li @click="clickMenu('智慧农业平台解决方案', '/agr-platform')">智慧农业平台解决方案</li>
          </ul>
        </div>
        <div class="footer-center-left-item">
          <h5>产品生态</h5>
          <ul>
            <!-- @click="toPage('/collection')" -->
            <li class="al" @click="clickMenu('智能质检', '/quality-check')">智能质检</li>
            <!-- @click="toPage('/shoppingMall')" -->
            <li class="al" @click="clickMenu('语音智控', '/ai-voice-ctrl')">语音智控</li>
            <!-- @click="toPage('/chatAi')" -->
            <li class="al" @click="clickMenu('智能光伏物联网监测平台', '/case2')">智能光伏物联网监测平台</li>
            <!-- @click="toPage('/digital')" -->
            <li class="al" @click="clickMenu('私有化大模型', '/gpt-private')">私有化大模型</li>
          </ul>
        </div>
        <!-- <div class="footer-center-left-item">
          <h5>快速入口</h5>
          <ul>
            <li>免费试用</li>
            <li>设计素材</li>
            <li>帮助中心</li>
            <li>视频中心</li>
          </ul>
        </div> -->
        <div class="footer-center-left-item">
          <h5>联系我们</h5>
          <ul>
            <li>联系电话 18955110833</li>
            <li>商务合作 wukai@quickdone.cn</li>
            <li>售后服务 wukai@quickdone.cn</li>
            <li>总部地址 合肥市蜀山区安徽智能软件产业园一期1号楼3层</li>
          </ul>
        </div>
      </div>
      <div class="footer-center-right">
        <div class="footer-center-right-item">
          <!-- <h5>关注我们</h5> -->
          <div class="gzh">
            <div class="img">
              <img src="@/assets/sdImages/code/gzh.png" alt="" />
            </div>
            <p class="title">扫一扫关注公众号</p>
            <!-- <p class="des">
							关注"北京善达信息"<br />
							及时获取最新资讯
						</p> -->
          </div>
        </div>
        <div class="footer-center-right-item">
          <!-- <h5></h5> -->
          <div class="gzh">
            <div class="img">
              <img src="@/assets/sdImages/code/kf.png" alt="" />
            </div>
            <p class="title">扫一扫联系客服</p>
            <!-- <p class="des">
							添加企业微信<br />
							了解更多详情
						</p> -->
          </div>
        </div>
        <!-- <div class="footer-center-right-item">
					<h5></h5>
					<div class="phone">
						<div class="img">
							<img src="@/assets/ico/icon-phone.png" alt="" />
						</div>
						<p class="title">400-8066-111</p>
						<a href="tel:4008066111">联系我们</a>
					</div>
				</div> -->
      </div>
    </div>
    <!-- <div class="footer-links Gwidth">
      <h5>友情链接：</h5>
      <a href="#" target="_blank">同徽信息</a>
      <a href="#">科大讯飞</a>
      <a href="#" target="_blank">安徽智时代</a>
    </div> -->
    <div class="footer-bottom Gwidth">
      <p>
        Copyright © 2002-2024 合肥善达信息科技有限公司 备案号：皖ICP备19006454号
      </p>

      <!-- <p>
				COPYRIGHT © 北京星润时代网络科技有限公司 2013-2022
				<a href="http://beian.miit.gov.cn" target="_blank">京ICP备14055839号-4</a>站长工具
				<a class="ahref" target="_blank" href="https://www.xingrunkeji.cn/index/icp.pdf">京网公安备：11011502005462号
				</a>
			</p> -->
      <!-- <div class="footer-bottom-ico">
				<img src="@/assets/images/bot1.png" alt="" />
				<img src="@/assets/images/bot2.png" alt="" />
			</div> -->
    </div>
    <backtop></backtop>
  </footer>
</template>
<script>
import backtop from "./backtop.vue";
import { staClickMenu } from '../utils/index';
export default {
  components: {
    backtop,
  },
  data() {
    return {
      active: "",
    };
  },
  created() {
    this.active = this.$route.path;
  },
  methods: {
    // 统计菜单点击
    clickMenu(menuName, url) {
      if (url) {
        this.$router.replace({
          path: url,
        });
      } else {
        if (menuName != '免费试用') {
          this.$toast("敬请期待");
        }
      }
      staClickMenu(menuName)
    },
    topage(e,val) {
      this.$router.replace({
        path: e,
        query: {
          current: val,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 0px) {
  footer {
    border-top: 1px solid #dcdee0;
    padding: 10px 0 20px 0;
    background-color: #1F2430;

    .footer-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      border-bottom: 1px solid #ebedf0;

      li {
        padding-left: 38px;
        font-size: 32px;
        line-height: 32px;
        font-weight: 600;
        color: #1d2129;
        width: 50%;
        box-sizing: border-box;
        margin-bottom: 20px;

        // &:nth-child(1) {
        //   background: url(~@/assets/ximg/icon-7@2x.png) left center no-repeat;
        //   background-size: 29px 29px;
        // }

        // &:nth-child(2) {
        //   background: url(~@/assets/ximg/icon-8.png) left center no-repeat;
        //   background-size: 29px 29px;
        // }

        // &:nth-child(3) {
        //   background: url(~@/assets/ximg/icon-9.png) left center no-repeat;
        //   background-size: 29px 29px;
        // }

        // &:nth-child(4) {
        //   background: url(~@/assets/ximg/icon-10.png) left center no-repeat;
        //   background-size: 29px 29px;
        // }
      }
    }

    .footer-center {
      padding: 30px 0 10px 0;

      &-left {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        border-bottom: 1px solid #ebedf0;
        padding-bottom: 30px;

        &-item {
          h5 {
            font-size: 28px;
            font-weight: 500;
            color: #ffffff;
            line-height: 28px;
          }

          ul {
            margin-top: 12px;

            li {
              font-size: 26px;
              font-weight: 400;
              color: #8c96a5;
              line-height: 38px;
              cursor: pointer;
            }

            li:hover {
              color: #ffffff;
            }
          }
        }
      }

      &-right {
        padding-top: 30px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        &-item {
          width: 33%;
          text-align: center;

          &:nth-child(1) {
            width: 34%;
          }

          h5 {
            font-size: 28px;
            font-weight: 500;
            color: #1d2129;
            text-align: left;
            margin-bottom: 22px;
          }

          .gzh {
            margin-top: 10px;

            img {
              width: 160px;
              height: 160px;
              border-radius: 8px;
            }

            .title {
              font-size: 26px;
              font-weight: 500;
              color: #ffffff;
            }

            .des {
              font-size: 24px;
              font-weight: 400;
              color: #ffffff;
            }
          }

          .phone {
            .img {
              width: 68px;
              height: 68px;
              display: flex;
              margin: 10px auto;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .title {
              font-size: 32px;
              font-weight: 500;
              color: #1d2129;
            }

            a {
              display: block;
              width: 100%;
              max-width: 192px;
              padding: 12px 0;
              background: #2e51fb;
              margin: 0 auto;
              margin-top: 24px;
              font-size: 28px;
              font-weight: 400;
              color: #ffffff;
            }

            margin-bottom: 10px;
          }
        }
      }
    }

    .footer-links {
      // border-top: 1px solid #ebedf0;
      padding: 10px 0 30px 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      h5 {
        font-size: 28px;
        font-weight: 500;
        color: #ffffff;
        margin-bottom: 10px;
      }

      a {
        font-size: 28px;
        font-weight: 400;
        color: #8c96a0;
        margin-right: 24px;
        margin-bottom: 10px;
      }

      a:hover {
        color: #ffffff;
      }
    }

    .footer-bottom {
      // border-top: 1px solid #ebedf0;
      padding-top: 16px;
      font-size: 24px;
      font-weight: 400;
      color: #cccccc;

      a {
        color: #cccccc;
      }

      &-ico {
        margin-top: 16px;

        img {
          width: 83px;
          height: 31px;
          margin-right: 10px;

          &:nth-child(2) {
            width: 98px;
            height: 31px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 760px) {
  footer {
    .footer-top {
      li {
        width: auto;
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 30px;
      }
    }

    .footer-center {
      &-left {
        &-item {
          h5 {
            // font-size: 14px;
            // line-height: 20px;
            font-family: Roboto, Roboto;
            font-weight: 400;
            font-size: 12px;
            color: #ffffff;
            line-height: 18px;
            text-align: left;
            font-style: normal;
            text-transform: none;
          }

          ul {
            li {
              // font-size: 14px;
              // line-height: 24px;
              font-family: Roboto, Roboto;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              text-align: left;
              font-style: normal;
              text-transform: none;
              margin-bottom: 13px;
            }
          }
        }
      }

      &-right {
        padding-top: 30px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        flex-direction: row;
        justify-content: flex-start;

        &-item {
          margin-right: 32px;

          h5 {
            font-size: 14px;
          }

          .gzh {
            img {
              width: 120px;
              height: 120px;
            }

            .title {
              margin-top: 17px;
              font-family: Roboto, Roboto;
              font-weight: 400;
              font-size: 12px;
              color: #FFFFFF;
              font-style: normal;
              text-transform: none;
            }

            .des {
              font-size: 14px;
            }
          }

          .phone {
            .img {
              width: 28px;
              height: 28px;
            }

            .title {
              font-size: 16px;
            }

            a {
              font-size: 14px;
              line-height: 36px;
              height: 36px;
              padding: 0;
            }
          }
        }
      }
    }

    .footer-links {
      h5 {
        // font-size: 14px;
        font-family: Roboto, Roboto;
        font-weight: 400;
        font-size: 12px;
        color: #ffffff;
        line-height: 18px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }

      a {
        // font-size: 14px;
        font-family: Roboto, Roboto;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }

    .footer-bottom {
      // font-size: 14px;
      text-align: center;
      font-family: Roboto, Roboto;
      font-weight: 400;
      font-size: 14px;
      color: #cccccc;
      font-style: normal;
      text-transform: none;
    }
  }
}

@media screen and (min-width: 820px) {
  footer {
    .footer-center {
      display: flex;
      justify-content: space-between;

      &-left {
        width: calc(50% - 20px);
        border-bottom: 0;
        padding-bottom: 0px;
        position: relative;

        // &::after {
        // 	content: "";
        // 	width: 1px;
        // 	height: 100%;
        // 	background-color: #dcdee0;
        // 	position: absolute;
        // 	right: -20px;
        // 	top: 50%;
        // 	transform: translateY(-50%);
        // }
      }

      &-right {
        width: calc(50% - 20px);
        padding-top: 0px;
      }
    }
  }
}

@media screen and (min-width: 1100px) {}

@media screen and (min-width: 1300px) {
  footer {
    .footer-center {
      &-left {
        width: calc(70% - 70px);

        &::after {
          right: -70px;
        }
      }

      &-right {
        width: calc(30%);
      }
    }
  }
}

.al {
  cursor: pointer;
}
</style>
